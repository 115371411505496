































































import './styles/ConstructRow.scss';
import './styles/ConstructRowAdaptive.scss';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IConstruct } from '@store/modules/work-list/interfaces/Interfaces';
import { WorkListActions } from '@store/modules/work-list/Types';
import { PopupActions } from '@store/Popup';
import { IWorkListPopupsState } from '@store/modules/work-list/modules/popups/Interfaces';
import { ITogglePopupPayload } from '@/interfaces/ITogglePopupPayload';

const NSUser = namespace('storeUser');
const NSWorkList = namespace('storeWorkList');
const NSWorkListPopups = namespace('storeWorkList/storeWorkListPopups');

@Component({
    name: 'ConstructRow',

    components: {
        'BaseButton': () => import('@components/buttons/BaseButton.vue'),
    },
})
export default class WorkList extends Vue {
    @Prop({ required: true }) construct!: IConstruct;
    @Prop({ default: false }) isOnTop!: boolean;

    @NSWorkList.Getter('constructsGrouping') constructsGrouping!: 'composite' | 'packs';
    @NSWorkList.Getter('addingExecutiveDocumentConstructId') addingExecutiveDocumentConstructId!: string | null;

    @NSWorkList.Action(WorkListActions.A_SET_ACTION_ON_CONSTRUCT)
    setActionOnConstruct!: (construct: IConstruct) => void;

    @NSWorkListPopups.Getter('constructDocumentationPopupToggler') constructDocumentationPopupToggler!: boolean;
    @NSUser.Getter('userRightOnlyGSN') userRightOnlyGSN!: boolean;

    @NSWorkListPopups.Action(PopupActions.A_TOGGLE_POPUP)
    togglePopup!: (
        payload: ITogglePopupPayload<IWorkListPopupsState>,
    ) => void;

    get addExecutiveDocumentBtnDisabled() {
        return this.addingExecutiveDocumentConstructId && this.addingExecutiveDocumentConstructId === this.construct.uuid;
    }

    get startDateString(): string {
        return new Intl.DateTimeFormat([], {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
        })
        .format(new Date(this.construct.startDate));
    }

    get endDateString(): string {
        return new Intl.DateTimeFormat([], {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
        })
            .format(new Date(this.construct.endDate));
    }

    get planString(): string {
        return this.construct.plan.toFixed(2) + ` ${this.construct.unit}`;
    }

    get factString(): string {
        return this.construct.fact.toFixed(2) + ` ${this.construct.unit}`;
    }

    get differenceFromPlan(): boolean {
        return this.construct.plan !== this.construct.fact;
    }

    viewConstructDocuments() {
        this.setActionOnConstruct(this.construct);
        this.togglePopup({
            popupToggler: 'constructDocumentationPopupToggler',
            state: !this.constructDocumentationPopupToggler,
        });
    }

    addExecutiveDocumentToConstruct() {
        if (this.construct.fact === 0) {
            this.setActionOnConstruct(this.construct);
            this.togglePopup({
                popupToggler: 'executiveDocumentAddingPopupToggler',
                state: true,
            });
        }
    }
}
